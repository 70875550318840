<template>
  <div class="login">
    <div class="login-logo">伊犁州托育机构质量评估标准平台</div>
    <el-form class="form" ref="pwdForm" label-width="0">
      <p class="input-label">账号</p>
      <el-form-item prop="username">
        <el-input placeholder="请输入用户名" clearable v-model="username" type="text"></el-input>
      </el-form-item>
      <p class="input-label">密码</p>
      <el-form-item>
        <el-input placeholder="请输入密码" clearable v-model="pwd" show-password></el-input>
      </el-form-item>
      <!-- <p class="input-label">图形验证码</p>
      <el-form-item class="form-item mb0">
        <el-input clearable placeholder="请输入图形验证码" v-model="code"></el-input>
        <div class="sms-btn" @click="getCode">
          <el-image class="code" :src="captcha" fit="cover"></el-image>
        </div>
      </el-form-item> -->
      <el-button :disabled="pwdCanSubmit" @click="pwdSubmit" class="login-btn" type="primary">登录</el-button>
      <!-- <router-link class="forget-link" to="/user/forget" tag="li">
          <el-link :underline="false" type="primary">忘记密码？</el-link>
        </router-link> -->
    </el-form>
    <div class="footer">
      <!-- 主办：国家卫生健康委员会<br>
      承办：中国儿童中心<br>-->
      技术支持：京学集团<br> 
      <p></p>
      <p></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      activeName: "pwd",
      labelPosition: "right",
      username: "",
      pwd: "",
      code: "",
      sms: "",
      smsCount: 0,
      smsBtnText: "发送验证码",
      smsTimer: null,
      captcha: '',
      client: '',
    };
  },
  computed: {
    isTest() {
      return location.href.indexOf('.test.') != -1 || location.href.indexOf(':808') != -1
    },
    pwdCanSubmit() {
      return !(this.username && this.pwd);
    },
    smsCanSubmit() {
      return !(this.username && this.sms && this.code);
    },
  },
  mounted() {
    let that = this;
    document.onkeypress = function (e) {
      var keycode = document.all ? event.keyCode : e.which;
      if (keycode == 13) {
        console.log('login')
        that.pwdSubmit()
        return false;
      }
    };
    // this.getCode();
  },
  components: {},
  methods: {
    async getCode() {
      let res = await this.$api({
        method: "get",
        url: "/api/img_code",
      });
      console.log(res);
      if (res.code == 0) {
        this.captcha = res.data.captcha
        this.client = res.data.client
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    handleClick() { },
    async pwdSubmit() {
      let data = {
        mobile: this.username,
        password: this.pwd,
        captcha: this.code,
        client: this.client,
      };
      // if (this.isTest) {
      //   data = {
      //     mobile: this.username,
      //     code: '04837',
      //   };
      // }
      let res = await this.$api({
        method: "get",
        url: "/api/auth/login",
        data: data,
      });
      console.log(res);
      if (res.code == 0) {
        this.$message("登录成功");
        localStorage.setItem("token", res.data.access_token);
        localStorage.setItem("username", res.data.username);
        localStorage.setItem("name", res.data.name)
        localStorage.setItem("organization_id", res.data.organization_id)
        localStorage.setItem("is_change_pswd", res.data.is_change_pswd);
        localStorage.setItem("is_organ_check", res.data.is_organ_check);
        localStorage.setItem("organization_name", res.data.organization_name);
        // role_id: 1——超级管理员; 2——国级评估专家; 3——市级评估专家; 4——机构管理员
        localStorage.setItem("role", res.data.role_id)
        this.$store.commit("SET_TOKEN", res.data.access_token);
        this.$store.commit("SET_NAME", res.data.name);
        this.$store.commit("SET_USERNAME", res.data.username);
        if (res.data.is_change_pswd == 0) {
          return this.$router.push({
            path: '/user/forget',
            query: {
              is_organ_check: 0
            }
          })
        }
        if (res.data.is_organ_check == 0 && res.data.role_id == 4) {
          return this.$router.push({
            path: '/user/refine',
            query: {
              is_organ_check: 0
            }
          })
        }
        this.$router.push({
          path: "/"
        })
      } else {
        // this.getCode();
        // this.code = '';
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }

    },
    smsSubmit() {
      this.$refs["pwdForm"].validate((valid) => {
        return valid;
      });
    },
    sendSms() {
      this.smsCount = 3;
      this.smsTimer = setInterval(() => {
        this.smsCount--;
        if (this.smsCount == 0) {
          clearInterval(this.smsTimer);
          this.smsBtnText = "重发验证码";
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss">
.login {
  min-height: 100vh;
  background: #fff url('../../assets/login_bg.png') no-repeat top;
  background-size: 100%;
  overflow: hidden;

  .login-logo {
    position: absolute;
    left: 24px;
    top: 24px;
    height: 60px;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
  }

  .form {
    background: #FFFFFF;
    border-radius: 16px 16px 0px 0px;
    margin-top: 300px;
    width: 100%;
    padding: 30px 24px;

    .input-label {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #AEB0BC;
      line-height: 12px;
      text-align: left;
    }
  }

  .logo {
    width: 169px;
    margin: 51px auto 40px;
  }

  .login-btn {
    width: 100%;
    margin-top: 24px;
    font-size: 19px;
    color: #ffffff;
    height: 40px;
  }

  .sms-btn {
    width: 95px;
    background: #f7f8fa;
    border-radius: 6px;
    flex: 0 0 95px;
    margin-left: 15px;
    display: flex;
    border: none;
    align-items: center;
    font-size: 14px;
    color: #0083F5;
  }

  .forget-link {
    width: 100%;
    display: block;
    margin-top: 18px;
    cursor: pointer;
    font-size: 14px;
  }

  .code {
    height: 100%;
    overflow: hidden;
    border-radius: 4px;
  }

  .footer {
    position: relative;
    top: 0;
    left: 0;
    margin-top: 40px;
  }
}
</style>

<style lang="scss">
.login {
  .el-input__inner {
    height: 36px;
    background: #fff;
    border-radius: 6px;
    font-size: 14px;
    border-bottom: 1px solid #eee;
    padding: 0;
  }
}

.login {
  .el-tabs__nav-scroll {
    display: flex;
    align-items: center;
    // justify-content: center;
    background: #fff;
  }

  .el-tabs__header {
    margin-bottom: 22px;
  }

  .el-form-item {
    margin-bottom: 16px;
  }

  .el-tabs__item {
    padding: 0 12px;
    font-size: 18px;
    width: 88px;
    color: #666666;
  }

  .el-tabs__active-bar {
    width: 30px !important;
    height: 4px;
    background: #0083f5;
    border-radius: 2px;
    margin-left: 23px;
    display: none;
  }

  .el-form-item__content {
    display: flex;
  }

  .el-tabs__item.is-active {
    color: #333333;
    font-weight: 500;
  }

  .el-tabs__nav-wrap::after {
    display: none;
  }
}
</style>
